import { createContext } from 'react'

export const initialAppState = {
  alertIsOpen: false,
  cookiesAllowed: false,
  cookieBannerVisible: true
}

export const AppContext = createContext(
  initialAppState
)
