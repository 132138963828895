import React, { useState } from 'react'
import { PageProps } from 'gatsby'

import { Seo } from '../Seo/Seo'
import { Analytics } from '../Analytics/Analytics';
import { AppContext, initialAppState } from '../../hooks/useAppContext'

import '../../scss/app.scss'

export default function App(props: PageProps): JSX.Element {
  const [state, setState] = useState(initialAppState)

  return (
    // @ts-expect-error
    <AppContext.Provider value={[state, setState]}>
      <Seo />
      <Analytics />
      <main>
        {props.children}
      </main>
    </AppContext.Provider>
  )
}
