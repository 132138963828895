import classNames from 'classnames';
import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../../hooks/useSiteMetadataQuery';

//import favicon from '../../images/icon.png'

interface SeoProps {
  title?: string,
  siteName?: string,
  description?: string,
  image?: string,
  siteUrl?: string,
  slug?: string,
  robots?: string,
  className?: string;
}

export const Seo = (data: SeoProps) => {
  const { siteMetadata } = useSiteMetadata()

  const {
    description,
    title,
    siteUrl,
  } = siteMetadata;

  /* Merge siteMetadata with props */
  // const {
  //   title,
  //   siteName,
  //   description,
  //   image,
  //   siteUrl,
  //   slug,
  //   robots
  // } = Object.assign({}, siteMetadata, data)

  // Generate a full URL to the content & image path
  // const url = slug ? (siteUrl + slug) : siteUrl
  // //const imageUrl = image || `${siteUrl}/assets/icon.png`
  // const imageUrl = 'https://ucarecdn.com/a7b6d760-fd50-4c72-a6fa-346b761763e3/1200.jpg'

  const formattedTitle = (): string => {
    if (data.title && data.title !== title) {
      return `${data.title} | ${title}`
    }

    if (data.title) {
      return data.title
    }

    return title
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        class: classNames('antialiased', data.className)
      }}
      //titleTemplate={`%s | ${title}`}
      defaultTitle={title}
    >
      <title>{formattedTitle()}</title>

      {/* Resource Hints */}
      {['https://www.googletagmanager.com'].map(resource => <link key={resource} rel='preconnect dns-prefetch' href={resource} />)}

      <meta name='description' content={description} />

      {/* Open Graph Tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={formattedTitle()} />
      <meta property='og:description' content={description} />
      {/* <meta property='og:url' content={url} /> */}
      <meta property='og:image' content={`${siteUrl}/assets/default-social.png`} />
      <meta property='og:image:alt' content={description} />
      <meta name='twitter:card' content='summary_large_image' />

      {/* {robots && <meta name='robots' content={robots} />} */}

      {/* Google Search Console */}
      {/* <meta name='google-site-verification' content='HHLwzSZzpjD1VAaf37WQyTMawoSfVFFtqPXVT3pThJc' /> */}

      {/* GIF Favicon */}
      {/* <link rel='shortcut icon' type='image/png' href={favicon} /> */}

    </Helmet>
  )
}
