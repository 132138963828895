/**
 * A custom hook built on React's useContext.
 *
 * Gives us an easier method to update and access state in
 * other components around the application, as and when we need to.
 *
 * Based on the tutorial from James King at Upmostly.
 * @see https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 *
 * @see: https://reactjs.org/docs/hooks-intro.html
 * @see: https://reactjs.org/docs/hooks-reference.html#usecontext
 */

import { useContext } from 'react'
import Cookies from 'js-cookie'

import { AppContext } from './useAppContext'

const useApp = () => {
  // @ts-expect-error
  const [state, setState] = useContext(AppContext)

  const setCookiesConsent = (accepted: boolean) => {
    localStorage.setItem('cookiesAllowed', accepted ? 'true' : 'false');

    setState({
      cookiesAllowed: accepted,
      cookieBannerVisible: false,
    })
  }

  return {
    setCookiesConsent,
    cookiesAllowed: state && state.cookiesAllowed || (typeof window !== 'undefined' && localStorage.getItem('cookiesAllowed') === 'true'),
    cookieBannerVisible: (typeof window !== 'undefined' && localStorage.getItem('cookiesAllowed') === null),
  }
}

export default useApp
