import React, { ReactElement } from 'react'
import useApp from '../../hooks/useApp'
import TagManager from 'react-gtm-module'

export const Analytics = (): ReactElement => {
  const { cookiesAllowed } = useApp()

  return (
    <>
      {cookiesAllowed && TagManager.initialize({
        gtmId: 'G-H7VZEC08QD'
      })}
    </>
  )
}
